import * as React from "react";
import { Helmet } from "react-helmet-async";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { useSiteMetadata } from "../siteMetadata";

type SeoProps = {
  language: string;
  title: string;
  description: string;
  location: Location;
}

const Seo: React.FC<SeoProps> = ({
  language,
  title,
  description,
  location,
}) => {
  const siteMetadata = useSiteMetadata();
  const twitterMetaDataSite = React.useMemo(() => {
    switch (language) {
      case "ko":
        return "@CRKingdomKR";
      case "en":
        return "@CRKingdomEN";
      case "ja":
        return "@CRKingdomJP";
      default:
        return null;
    }
  }, [language]);
  return (
    <>
    <Helmet>
      <html lang={language} />
    </Helmet>
    <GatsbySeo
      title={title}
      description={description}
      canonical={siteMetadata.siteUrl + location.pathname}
      openGraph={{
        type: "website",
        url: siteMetadata.siteUrl + location.pathname,
        title: title,
        description: description,
        images: [
          {
            url: `${siteMetadata.siteUrl}/og-${language}.jpg`,
            width: 1200,
            height: 630,
            alt: title,
          },
        ],
        site_name: title,
      }}
      facebook={
        process.env.GATSBY_FACEBOOK_APP_ID
          ? {
            appId: process.env.GATSBY_FACEBOOK_APP_ID,
          }
          : undefined
      }
      twitter={
        twitterMetaDataSite
          ? {
            site: twitterMetaDataSite,
            handle: "@devsisters",
            cardType: "summary_large_image",
          }
          : undefined
      }
    />
    </>
  )
};

export default Seo;
